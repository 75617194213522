import Ready from '@/utils/ready';
import Vue from 'vue';
import HomepageHotspots from '@/components/organisms/homepageHotspots.vue';
// import Scroller from '@/js/scroller';
// @ts-ignore;
import uuid from 'uuid';

const selector = '[data-v-component="homepageHotspots"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    if (!element.getAttribute('data-watched')) {
      element.setAttribute('data-watched', 'true');
      const attributes = Array.from((element as any).attributes) || [];
      new (Vue as any)({
        components: {
          HomepageHotspots,
        },
        template: `<HomepageHotspots ${attributes
          .map((attribute: any) => `${attribute.name}='${attribute.value}'`)
          .reduce(
            (accumulator: string, currentValue: string) =>
              accumulator + ' ' + currentValue,
            '',
          )} />`,
      }).$mount(element);
    }
  });
})();

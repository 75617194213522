

























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { find, get } from 'lodash';
import scrollTo from '@/js/scrollTo';

@Component({
  name: 'CategorySolutionFilterLevel',
  components: {
    CategorySolutionFilterLevel,
  },
})
export default class CategorySolutionFilterLevel extends Vue {
  @Prop({ required: true }) categoryId!: number;
  @Prop({ required: true }) categories!: any[];
  @Prop({ default: 0 }) level!: number;
  @Prop({ required: true }) path!: string;
  @Prop({ required: true }) locale!: string;
  @Prop({ default: '' }) dataTransSeeSolutions!: string;

  get category(): any {
    return find(
      this.categories,
      (category: any) => category.id === this.categoryId,
    );
  }

  get children() {
    if (get(this, 'category.children.id')) {
      return this.category.children.id.map((childCategory: any) =>
        find(this.categories, (category: any) => category.id === childCategory),
      );
    }
    return [];
  }

  setPath(path: any, e: any) {
    if (this.$store.state.categorySolutionPath === path) {
      this.$store.dispatch(
        'setCategorySolutionPath',
        this.getPreviousPath(path),
      );
    } else {
      this.$store.dispatch('setCategorySolutionPath', path);
      const levelParent = e.closest('[data-ref="categorySolutionLevel"]');
      if (levelParent) scrollTo(levelParent, -levelParent.offsetHeight + 30);
    }
  }

  getPreviousPath(path: string) {
    const oldPathArray = path.split('/');
    oldPathArray.pop();
    return oldPathArray.join('/');
  }
}

/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/ObjectAssign';
import '@/polyfills/ArrayFrom';
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';
import 'alpinejs/dist/alpine-ie11';
// @ts-ignore
import MicroModal from '@/vendors/micromodal.min.js';
import mitt from 'mitt';
import scrollTo from '@/js/scrollTo';
import ElementQueries from 'css-element-queries/src/ElementQueries';
ElementQueries.listen();

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/* Choose an icon library */

/** Ionicons: https://ionicons.com/ */
// import 'ionicons/dist/css/ionicons.min.css';

/** Fontisto: https://www.fontisto.com/icons */
// import 'fontisto';

/** Feather: https://feathericons.com/ */
// @ts-ignore
// import feather from 'feather-icons';
// import Ready from '@/utils/ready';
// (() => {
//     Ready.watch('[data-feather]', () => {
//         feather.replace();
//     });
// })();

/** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/unicons.css';

/* Import Styles */
import '@/styles/main.scss';

/* Auto Imports */
const srcRuntimes = require.context('@', true, /\.runtime\.(js|ts|vue)$/);
srcRuntimes.keys().forEach(srcRuntimes);

const templateRuntimes = require.context(
  '@root/templates/frontend/atomic',
  true,
  /\.runtime\.(js|ts)$/,
);
templateRuntimes.keys().forEach(templateRuntimes);
/* Import Assets */

(window as any).emitter = mitt();
(window as any).scrollToEl = scrollTo;
(window as any).MicroModal = MicroModal;
(window as any).MicroModal.init();









































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';

@Component
export default class Closimg extends Vue {
  @Prop() private src!: string;
  @Prop() private alt!: string;
  @Prop({ default: 'contain' }) private size!: string;
  @Prop({ default: false }) private overflow!: boolean;
  private width: number = 0;
  private height: number = 0;
  private imageObject!: any;

  created() {}

  mounted() {
    if (this.src) {
      this.createclosimg();
    }
  }

  private createclosimg() {
    this.imageObject = new Image();
    this.imageObject.onload = () => {
      this.width = this.imageObject.naturalWidth;
      this.height = this.imageObject.naturalHeight;
      this.resize();
      this.bindListeners();
    };
    this.imageObject.src = this.src;
  }

  beforeDestroy() {}

  private get imageRatio() {
    return this.width / this.height;
  }

  private resize() {
    let parentWidth = (this.$refs.closimg as any).offsetWidth;
    let parentHeight = (this.$refs.closimg as any).offsetHeight;
    let parentRatio = parentWidth / parentHeight;
    if (
      (this.imageRatio > parentRatio && this.size === 'contain') ||
      (this.imageRatio < parentRatio && this.size === 'cover')
    ) {
      (this.$refs.closimgImageWrapper as any).style.cssText = `
                        width: ${parentWidth}px;
                        height: ${parentWidth / this.imageRatio}px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        max-width: initial;
                    `;
    } else if (
      (this.imageRatio < parentRatio && this.size === 'contain') ||
      (this.imageRatio > parentRatio && this.size === 'cover')
    ) {
      (this.$refs.closimgImageWrapper as any).style.cssText = `
                        width: ${parentHeight * this.imageRatio}px;
                        height: ${parentHeight}px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        max-width: initial;
                    `;
    } else {
      (this.$refs.closimgImageWrapper as any).style.cssText = `
                        width: ${parentWidth}px;
                        height: ${parentHeight}px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        max-width: initial;
                    `;
    }
  }

  destroyed() {
    window.removeEventListener('resize', this.resize);
  }

  private bindListeners() {
    window.addEventListener('resize', this.resize);
  }
}

const spacing = {
  '0': '0px',
  xs: '5px',
  sm: '10px',
  md: '15px',
  base: '15px',
  lg: '30px',
  xl: '45px',
  '2xl': '60px',
  '3xl': '100px',
  '16_9': '56.25%',
};
const colors = {
  primary: '#1D3856',
  secondary: '#5BBDDD',
  tertiary: '#3D719D',
  whiteImportant: '#fff',
  grey: '#EEEEEE',
  'grey-darken': '#68788F',
};
const pristineConfig = {
  screens: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xl2: '1400px',
    xl3: '1600px',
  },
  gutters: {
    ...spacing,
  },
  colors: {
    ...colors,
  },
  spacing: {
    ...spacing,
  },
  backgroundColor: {
    ...colors,
  },
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {
    ...colors,
    light: '#E5E5E5',
    base: '#B7B7B7',
    grey: '#A8A8A8',
  },
  borderRadius: {
    xs: '2px',
    base: '10px',
    lg: '15px',
  },
  borderWidth: {
    base: '1px',
  },
  boxShadow: {
    header: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    brand: [
      'Syncopate',
      'Montserrat',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    sans: [
      'Montserrat',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  fontSize: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    base: '14px',
    lg: '18px',
    xl: '24px',
    '2xl': '28px',
  },
  fontWeight: {},
  height: {
    base: '56px',
  },
  inset: {},
  letterSpacing: {
    xs: '0.015rem',
    base: '0.025rem',
    lg: '0.05rem',
    xl: '0.1rem',
    xxl: '0.2rem',
  },
  lineHeight: {},
  listStyleType: {},
  margin: {
    ...spacing,
  },
  maxHeight: {},
  maxWidth: {},
  minHeight: {
    sm: '30px',
    base: '56px',
    lg: '70px',
    xl: '250px',
  },
  minWidth: {
    base: '56px',
    xl: '250px',
  },
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {
    ...spacing,
  },
  stroke: {},
  textColor: {},
  width: {
    base: '56px',
  },
  zIndex: {},
};

module.exports = pristineConfig;

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    categorySolutionsLevel: 0,
    categorySolutionPath: '',
  },
  mutations: {
    setCategorySolutionsLevel(state, value) {
      state.categorySolutionsLevel = value;
    },
    setCategorySolutionPath(state, value) {
      state.categorySolutionPath = value;
    },
  },
  actions: {
    setCategorySolutionsLevel({ commit }, value) {
      commit('setCategorySolutionsLevel', value);
    },
    setCategorySolutionPath({ commit, dispatch }, value) {
      commit('setCategorySolutionPath', value);
      dispatch('setCategorySolutionsLevel', value.split('/').length - 1);
    },
  },
  modules: {},
});






































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Popover } from 'element-ui';
import 'element-ui/lib/theme-chalk/popover.css';
import Closimg from '@/components/molecules/closimg.vue';
@Component({
  components: {
    Closimg,
    'el-popover': Popover,
  },
})
export default class HomepageHotspots extends Vue {
  @Prop() dataImage!: string;
  @Prop() dataAlt!: string;
  @Prop({ default: '[]' }) dataHotspots!: string;
  mounted() {}
  get hotspots() {
    return JSON.parse(this.dataHotspots);
  }
}

import Ready from '@/utils/ready';
// @ts-ignore
import 'lightgallery.js';
import 'lightgallery.js/dist/css/lightgallery.min.css';
import 'lightgallery.js/dist/css/lg-transitions.min.css';
const selector = '[data-lightgallery]';
declare const lightGallery: any;

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    lightGallery(element, {
      selector: '[data-src]',
      download: false,
    });
  });
})();

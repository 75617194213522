import Ready from '@/utils/ready';

const selector = '[data-ref="header"]';

class Header {
  private el: HTMLCollection;
  private elToggler: HTMLCollection;
  private elNavResponsive: HTMLCollection;

  constructor(element: HTMLCollection) {
    this.el = element;
    this.elToggler = (this.el as any).querySelector(
      '[data-ref="headerToggler"]',
    );
    this.elNavResponsive = (this.el as any).querySelector(
      '[data-ref="headerNavResponsive"]',
    );
    this.watchEvents();
  }

  private watchEvents() {
    (this.elToggler as any).addEventListener('click', (e: any) => {
      (this.elNavResponsive as any).classList.toggle('active');
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLCollection) => {
    new Header(element);
  });
})();

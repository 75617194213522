// import * as gsap from 'gsap';
//@ts-ignore
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

function isElement(obj: any) {
  try {
    //Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    //Browsers not supporting W3 DOM2 don't have HTMLElement and
    //an exception is thrown and we end up here. Testing some
    //properties that all elements have (works on IE7)
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    );
  }
}

function getOffsetTop(element: any) {
  let offsetTop = 0;
  while (element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

export default (
  elementOrSelector: HTMLElement | string,
  offset: number = 0,
) => {
  const header = <any>document.querySelector('[data-ref="header"]');
  const element = <any>isElement(elementOrSelector)
    ? elementOrSelector
    : document.querySelector(<string>elementOrSelector);
  if (element) {
    const scrollTarget =
      getOffsetTop(element as any) - (header as any).offsetHeight;
    gsap.to(window, {
      duration: 0.5,
      scrollTo: { y: scrollTarget, offsetY: offset },
    });
    // window.scroll({
    //   top: (element as any).offsetTop + (header as any).offsetHeight,
    //   left: 0,
    //   behavior: 'smooth',
    // });
  }
};

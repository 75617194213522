import Vue from 'vue';
import App from './App.vue';
import store from './store';
import { get } from 'lodash';
import '@/js/assets';

const ElementQueries = require('css-element-queries/src/ElementQueries');
ElementQueries.listen();
ElementQueries.init();

Vue.config.productionTip = false;
Vue.prototype.$get = get;
// new Vue({
//   store,
//   render: (h) => h(App),
// }).$mount('#app');

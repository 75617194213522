import Ready from '@/utils/ready';

const selector = '[data-ref="customInputFile"]';

class inputFile {
  private el: HTMLElement;
  private textZone: HTMLElement | null;
  private input: HTMLElement | null;

  constructor(element: HTMLElement) {
    this.el = element;
    this.textZone = this.el.querySelector('[data-ref="customInputFileText"]');
    this.input = this.el.querySelector('input[type="file"]');
    if (this.input) {
      this.init();
    }
  }

  private init() {
    if ((this as any).input.files.length > 0) {
      (this as any).textZone.innerHTML = (this as any).input.files[0].name;
    }
    (this as any).input.addEventListener('change', (e: any) => {
      const filename = e.target.files[0].name;
      (this as any).textZone.innerHTML = filename;
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    new inputFile(element);
  });
})();

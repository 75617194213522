import Ready from '@/utils/ready';
// @ts-ignore
import objectFitImages from 'object-fit-images';
// var objectFitImages = require('object-fit-images');

const selector = '[data-ref="object-fit"]';

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    objectFitImages(element);
  });
})();

import Ready from '@/utils/ready';
// import Scroller from '@/js/scroller';
// @ts-ignore;
import uuid from 'uuid';

const selector = 'form';

class FormValidator {
  el: any = null;
  inputs: any = [];

  constructor(el: any) {
    this.el = el;
    this.getInputs();
    this.watchInputs();
    this.watchSubmit();
  }

  private getInputs() {
    [
      Array.from(this.el.querySelectorAll('input')),
      Array.from(this.el.querySelectorAll('select')),
      Array.from(this.el.querySelectorAll('textarea')),
    ].forEach((inputs) => {
      this.inputs = [
        ...this.inputs,
        ...inputs.map((input) => ({ element: input, id: uuid() })),
      ];
    });
  }

  private watchInputs() {
    this.inputs.forEach((input: any) => {
      const { element, id } = input;
      element.addEventListener('change', () => {
        this.checkForError(input);
      });
    });
  }

  private checkForError(input: any) {
    const { element, id } = input;
    const errorElement = document.querySelector(
      `[data-input-error-message="${id}"]`,
    );
    if (errorElement) {
      element.removeAttribute('data-input-error');
      errorElement.remove();
    }
    if (!element.validity.valid) {
      element.setAttribute('data-input-error', id);
      this.createError(input);
    }
  }

  private createError(input: any) {
    const { element, id } = input;
    const errorElement = document.createElement('div');
    errorElement.innerHTML = element.validationMessage;
    errorElement.setAttribute('data-input-error-message', id);
    element.parentNode.insertBefore(errorElement, element.nextSibling);
  }

  private watchSubmit() {
    this.el.addEventListener('submit', (e: any) => {
      this.inputs.forEach((input: any) => {
        const { element, id } = input;
        if (!element.validity.valid) {
          this.checkForError(input);
          e.preventDefault ? e.preventDefault() : (e.returnValue = false);
        }
      });
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    new FormValidator(element);
  });
})();

































import { Vue, Component, Prop } from 'vue-property-decorator';
import CategorySolutionFilterLevel from '@/components/organisms/CategorySolutionFilterLevel.vue';
import axios from 'axios';
import Ready from '@/utils/ready';
import store from '@/store';
import PortalVue from 'portal-vue';
Vue.use(PortalVue);
@Component({
  components: {
    CategorySolutionFilterLevel,
  },
})
export default class CategorySolutionFilter extends Vue {
  @Prop({ required: true }) dataCurrentCategory!: string;
  @Prop({ required: true }) dataLocale!: string;
  @Prop({ required: false, default: '' }) dataTransSeeSolutions!: string;
  private data: any[] = [];
  mounted() {
    axios
      .get('/api/menu')
      .then((res) => {
        this.data = res.data.data;
        this.$store.dispatch(
          'setCategorySolutionPath',
          this.dataCurrentCategory,
        );
        const path = this.queryStringParams['path'];
        if (path) {
          this.$store.dispatch('setCategorySolutionPath', path);
        }
      })
      .catch((err) => console.error(err));
  }

  get queryStringParams() {
    const query: any = window.location.search;
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params: any, param: any) => {
            let [key, value] = param.split('=');
            params[key] = value
              ? decodeURIComponent(value.replace(/\+/g, ' '))
              : '';
            return params;
          }, {})
      : {};
  }
}

(() => {
  Ready.watch(
    '[data-v-component="categorySolutionFilter"]',
    (element: HTMLElement) => {
      if (!element.getAttribute('data-watched')) {
        element.setAttribute('data-watched', 'true');
        const attributes = Array.from((element as any).attributes) || [];
        new (Vue as any)({
          store,
          components: {
            CategorySolutionFilter,
          },
          template: `<CategorySolutionFilter ${attributes
            .map((attribute: any) => `${attribute.name}='${attribute.value}'`)
            .reduce(
              (accumulator: string, currentValue: string) =>
                accumulator + ' ' + currentValue,
              '',
            )} />`,
        }).$mount(element);
      }
    },
  );
})();

import 'clampify';
import Ready from '@/utils/ready';

const selector = '[data-clampify]';
declare const $clampify: any;

const init = (el: any = null) => {
  const lines = Number(el.dataset.clampify) || 0;
  ($clampify as any)(el, {
    maxLines: lines,
    endsWith: '...',
    autoUpdate: true,
  });
};

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    init(element);
  });
})();

export default init;

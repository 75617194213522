





































































































































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
// import { Loading } from 'element-ui';
// import 'element-ui/lib/theme-chalk/loading.css';
// Vue.use(Loading.directive);
import axios from 'axios';
import Ready from '@/utils/ready';
import store from '@/store';
import { uniqBy } from 'lodash';
import scrollTo from '@/js/scrollTo';
const scrollToTarget = '#marketFilter';
const scrollToTarget2 = '#marketFilter2';
const scrollToTarget3 = '#marketFilter3';
import { Collapse, CollapseItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/collapse.css';
import 'element-ui/lib/theme-chalk/collapse-item.css';
@Component({
  components: {
    MarketFilter,
    Collapse,
    CollapseItem,
  },
})
export default class MarketFilter extends Vue {
  @Prop({ required: false, default: '' }) dataTransApplications!: string;
  @Prop({ required: false, default: '' }) dataTransSolutions!: string;
  @Prop({ required: false, default: '' }) dataTransSeeSolutions!: string;
  @Prop({ required: false, default: '' })
  dataTransApplicationSelectPlaceholder!: string;
  @Prop({ required: true }) dataCurrentMarket!: string;
  @Prop({ required: true, default: '[]' }) dataApplications!: string;
  @Prop({ required: true }) dataLocale!: string;
  private isFixed: boolean = false;
  private selectedApplication: any = null;
  private selectedCategory: any = null;
  private data: any[] = [];
  private activeCollapse: any = null;

  mounted() {
    this.addEventListeners();
  }

  addEventListeners() {
    window.addEventListener('scroll', () => {
      let elementTarget: any = this.$refs['filters'];
      if (
        window.scrollY >
        elementTarget.offsetTop + elementTarget.offsetHeight
      ) {
        return (this.isFixed = true);
      }
      return (this.isFixed = false);
    });
  }

  setApplication(id: number) {
    if (this.selectedApplication !== Number(id)) {
      this.selectedApplication = Number(id);
      this.selectedCategory = null;
      this.fetchApplicationData().then(() => {
        if (window.innerWidth > 998) {
          scrollTo(scrollToTarget);
        } else {
          scrollTo(scrollToTarget2);
        }
      });
    }
  }

  setCategory(id: number) {
    if (window.innerWidth > 998) {
      scrollTo(scrollToTarget2);
    } else if (window.innerWidth < 590) {
      scrollTo(scrollToTarget3, 150);
    }
    this.selectedCategory = Number(id);
  }

  scrollTo(event: any) {
    // scrollTo(event.target);
  }

  fetchApplicationData() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/machine/${this.dataCurrentMarket}/${this.selectedApplication}`,
        )
        .then((res) => {
          this.data = res.data.data;
          resolve();
        })
        .catch((err) => console.error(err));
    });
  }

  get applications() {
    return JSON.parse(this.dataApplications);
  }

  get categories() {
    let categories: any[] = [];
    this.data.forEach((machine) => {
      machine.categories.forEach((category: any) => categories.push(category));
    });
    categories = uniqBy(categories, (category) => category.id);
    categories = categories.map((category) => ({
      ...category,
      machines: this.data.filter((machine) => {
        return machine.categories.some((item: any) => item.id === category.id);
      }),
    }));
    return categories;
  }
}

(() => {
  Ready.watch('[data-v-component="marketFilter"]', (element: HTMLElement) => {
    if (!element.getAttribute('data-watched')) {
      element.setAttribute('data-watched', 'true');
      const attributes = Array.from((element as any).attributes) || [];
      new (Vue as any)({
        store,
        components: {
          MarketFilter,
        },
        template: `<MarketFilter ${attributes
          .map((attribute: any) => `${attribute.name}='${attribute.value}'`)
          .reduce(
            (accumulator: string, currentValue: string) =>
              accumulator + ' ' + currentValue,
            '',
          )} />`,
      }).$mount(element);
    }
  });
})();

// @ts-ignore
import * as Swiper from 'swiper/js/swiper.js';
import 'swiper/css/swiper.min.css';
import Ready from '@/utils/ready';

const selector = '[data-ref="slider"]';

class Slider {
  el: any;
  instance: any = null;

  constructor(el: any, options = {}) {
    this.el = el.querySelector('.swiper-container');
    if (!this.el) {
      throw new Error('Hero Slider requires a Selector or an HTML Element.');
    }
    this.instantiate(this.el, options);
  }

  private instantiate(el: any, options: any) {
    this.instance = new Swiper.default(el, {
      ...options,
    });
  }
}

(() => {
  Ready.watch(selector, (element: HTMLElement) => {
    const id = element.dataset.refId;
    const options = element.dataset.options
      ? JSON.parse(element.dataset.options as any)
      : {};
    return new Slider(element, {
      navigation: {
        nextEl: `[data-ref="slider-button-next"][data-ref-id="${id}"]`,
        prevEl: `[data-ref="slider-button-prev"][data-ref-id="${id}"]`,
      },
      ...options,
    });
  });
})();

import { render, staticRenderFns } from "./closimg.vue?vue&type=template&id=a607cf86&scoped=true&"
import script from "./closimg.vue?vue&type=script&lang=ts&"
export * from "./closimg.vue?vue&type=script&lang=ts&"
import style0 from "./closimg.vue?vue&type=style&index=0&id=a607cf86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a607cf86",
  null
  
)

export default component.exports